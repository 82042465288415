<template>
  <div class="publishStatistics">
    <div v-show="currentStep === 1">
      <a-alert message="推送了笔记后，统计门店发布情况" type="info" show-icon />
      <div class="search_list">
        <div>
          <a-space>
            <div class="search_brand">
              <span
                :class="['brand_item', !searchForm.principalId ? 'active_item' : '']"
                @click="handleBrandClick(undefined)"
                >全部品牌</span
              >
              <span
                :class="['brand_item', searchForm.principalId === item.principalId ? 'active_item' : '']"
                v-for="item in allBrandList"
                :key="item.principalId"
                @click="handleBrandClick(item.principalId)"
                >{{ item.principalName }}</span
              >
            </div>
            <a-select
              style="width: 200px"
              v-model="searchForm.operatorGroupId"
              placeholder="请选择团队"
              show-search
              option-filter-prop="children"
              allowClear
              @change="getList(true)"
            >
              <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
                item.groupName
              }}</a-select-option>
            </a-select>

            <a-select
              v-model="searchForm.operatorId"
              style="width: 200px"
              placeholder="运营人"
              show-search
              option-filter-prop="children"
              @change="getList(true)"
              allowClear
            >
              <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
                item.operatorName
              }}</a-select-option>
            </a-select>

            <a-range-picker
              style="width: 220px"
              :placeholder="['统计时间', '统计时间']"
              :value="checkTimeRange"
              @change="onTimeChange"
              allowClear
            />
          </a-space>
        </div>

        <div style="display: flex; justify-content: space-between; margin: 10px 0">
          <a-input-search
            style="width: 380px; margin-right: 15px"
            placeholder="搜索小红书昵称、小红书号、成员名称、成员编码"
            v-model.trim="searchForm.search"
            @search="getList(true)"
            allowClear
          ></a-input-search>
          <div style="display: flex; align-items: center">
            <div style="margin-right: 15px; color: #838383">
              <span>数据更新时间：</span><span>{{ updateTime }}</span>
            </div>
            <a-tooltip placement="bottom">
              <template slot="title">
                <p>
                  迟发布：设置了定时发布的笔记推送后，门店进行一键发布/分享发布时，没有早于定时时间一小时（立即发布的笔记没有迟发布）
                </p>
                <p>未发布：定时/立即发布的笔记推送后，门店当日24点前未进行一键发布/分享发布</p>
                <p>
                  总次数 = 迟发布次数+未发布次数+正常发布次数<br />
                  迟发布率：迟发布次数/总次数<br />
                  未发布率：未发布次数/总次数<br />
                </p>
              </template>
              <span style="cursor: pointer"><a-icon type="question-circle" style="margin-right: 5px" />数据说明</span>
            </a-tooltip>
            <a-button style="margin: 0 15px" @click="handleJumpToHistory()">查看发布记录</a-button>
            <a-button type="primary" :loading="exportLoading" @click="handleExport()">导出</a-button>
          </div>
        </div>
      </div>
      <a-table
        :loading="tableLoading"
        :rowKey="(record, index) => index"
        :columns="columns"
        :data-source="dataList"
        :pagination="false"
        @change="onTableChange"
      >
        <div slot="member" slot-scope="text, record">
          <div>名称: {{ record.memberName || '-' }}</div>
          <div>编号: {{ record.memberCode || '-' }}</div>
        </div>

        <div slot="operation" slot-scope="text, record">
          <a-button type="link" @click="handleJumpToHistory(record.memberCode)">详情</a-button>
        </div>
      </a-table>
      <base-pagination
        :currentPage="pagination.current"
        :options="['15', '30', '50']"
        :pageSize="pagination.pageSize"
        :total="pagination.total"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </div>
    <publishHistory ref="publishHistory" v-show="currentStep === 2" @back="currentStep = 1" />
  </div>
</template>

<script>
import { columns } from './data';
import moment from 'moment';
import publishHistory from './publishHistory.vue';
import { downloadExcel } from '@/utils';
import api from './api';

export default {
  name: 'publishStatistics',
  components: { publishHistory },
  data() {
    return {
      columns,
      currentStep: 1,
      allTeamList: [],
      allMemberList: [],
      allBrandList: [],
      dataList: [],
      tableLoading: false,
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
      },
      searchForm: {
        principalId: undefined,
        operatorGroupId: undefined,
        operatorId: undefined,
        search: undefined,
      },
      checkTimeRange: [moment().startOf('week'), moment().endOf('week')],
      exportLoading: false,
      updateTime: undefined,
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      this.getAllMemberList();
      this.getAllBrandList();
      this.getOperatorTeamList();
      this.getList();
    },
    async getList(initPage = false) {
      this.getUpdateTime();
      if (initPage) this.pagination.current = 1;
      const startTime = this.checkTimeRange[0]
        ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`
        : undefined;
      const endTime = this.checkTimeRange[1]
        ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`
        : undefined;
      const param = {
        ...this.searchForm,
        objectType: 'NOTE_PUSH',
        startTime,
        endTime,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.tableLoading = true;
      const { code, message, data } = await api
        .getPublishStatisticsList(param)
        .finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.dataList = data.list;
        this.pagination.total = data.total;
      } else {
        this.$message.error(message);
      }
    },
    handleBrandClick(value) {
      this.searchForm.principalId = value;
      this.searchForm.operatorGroupId = undefined;
      this.allTeamList = [];
      this.getOperatorTeamList(value);
      this.getList(true);
    },
    async getOperatorTeamList(principalId = undefined) {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999, principalId });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async getAllBrandList() {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api.getBrandManageList({ size: 999, page: 1 });
        if (code === 200) {
          this.allBrandList = data.list.filter((v) => v.status === 'NORMAL');
        } else {
          this.$message.error(message);
        }
        resolve();
      });
    },
    async getAllMemberList() {
      const { code, data, message } = await api.getTeamMember({ page: 1, size: 999, isDeleted: 0 });
      if (code === 200) {
        this.allMemberList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async onTimeChange(dates) {
      this.checkTimeRange = [dates[0], dates[1]];
      this.getList();
    },
    onTableChange(pagination, filters, sorter) {
      if (sorter.order) {
        this.searchForm.orderField = sorter.columnKey;
        this.searchForm.sortOrder = sorter.order == 'ascend' ? 'asc' : 'desc';
      } else {
        this.searchForm.orderField = undefined;
        this.searchForm.sortOrder = undefined;
      }
      this.getList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    handleJumpToHistory(searchTxt = undefined) {
      this.currentStep = 2;
      this.$refs.publishHistory.getList(true, searchTxt);
    },
    async handleExport() {
      const startTime = this.checkTimeRange[0]
        ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`
        : undefined;
      const endTime = this.checkTimeRange[1]
        ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`
        : undefined;
      const param = {
        ...this.searchForm,
        objectType: 'NOTE_PUSH',
        startTime,
        endTime,
        page: this.pagination.current,
        size: this.pagination.pageSize,
      };
      this.exportLoading = true;
      const res = await api.downloadPublishStatistics(param).finally(() => (this.exportLoading = false));
      const date = moment().format('YYYY-MM-DD');
      downloadExcel(res, `${date} - 发布统计`);
    },
    async getUpdateTime() {
      const { code, data, message } = await api.getUpdateTime({ objectType: 'NOTE_PUSH' });
      if (code === 200) {
        this.updateTime = data;
      } else {
        this.$message.error(message);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.search_list {
  margin: 10px 0;
  .search_brand {
    .brand_item {
      color: #adadad;
      padding: 5px 10px;
      border: 1px solid #bfbfbf;
      cursor: pointer;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  /deep/ .ant-input-group {
    width: unset;
  }
}

.search_brand {
  .all_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    border-radius: 6px;
  }

  .brand_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    background-color: #fff;
    line-height: 30px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.active_item {
      border: 1px solid #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }
}
</style>
