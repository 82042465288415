export const columns = [
  {
    title: '成员',
    width: 240,
    scopedSlots: { customRender: 'member' },
  },
  {
    align: 'center',
    title: '所属品牌',
    dataIndex: 'principalName',
    customRender: (record) => {
      return record || '-';
    },
  },
  {
    align: 'center',
    title: '运营人',
    dataIndex: 'operatorName',
    customRender: (record) => {
      return record || '-';
    },
  },
  {
    title: '迟发布次数',
    sorter: true,
    align: 'center',
    dataIndex: 'latePublishCount',
    customRender: (text) => {
      return text || 0;
    },
  },
  {
    title: '迟发布率',
    sorter: true,
    align: 'center',
    dataIndex: 'latePublishRate',
    customRender: (text) => {
      return `${text || 0}%`;
    },
  },
  {
    title: '未发布次数',
    sorter: true,
    align: 'center',
    dataIndex: 'unpublishCount',
    customRender: (text) => {
      return text || 0;
    },
  },
  {
    title: '未发布次率',
    sorter: true,
    align: 'center',
    dataIndex: 'unpublishRate',
    customRender: (text) => {
      return `${text || 0}%`;
    },
  },
  {
    title: '总次数',
    align: 'center',
    dataIndex: 'eventCount',
    customRender: (text) => {
      return text || 0;
    },
  },
  {
    align: 'center',
    title: '操作',
    width: 160,
    scopedSlots: { customRender: 'operation' },
  },
];

export const historyColumns = [
  {
    title: '事件',
    scopedSlots: { customRender: 'event' },
  },
  {
    title: '关联成员',
    scopedSlots: { customRender: 'relate' },
  },
  {
    title: '关联笔记',
    scopedSlots: { customRender: 'writtingPreview' },
  },
  {
    align: 'center',
    title: '运营人',
    dataIndex: 'operatorName',
    customRender: (record) => {
      return record || '-';
    },
  },
  {
    align: 'center',
    title: '记录时间',
    dataIndex: 'eventTime',
    customRender: (record) => {
      return record || '-';
    },
  },
];
