<template>
  <div class="publishHistory">
    <a-button @click="handleBack">返回</a-button>
    <div class="search_list">
      <div>
        <a-space>
          <div class="search_brand">
            <span
              :class="['brand_item', !searchForm.principalId ? 'active_item' : '']"
              @click="handleBrandClick(undefined)"
              >全部品牌</span
            >
            <span
              :class="['brand_item', searchForm.principalId === item.principalId ? 'active_item' : '']"
              v-for="item in allBrandList"
              :key="item.principalId"
              @click="handleBrandClick(item.principalId)"
              >{{ item.principalName }}</span
            >
          </div>
          <a-select
            style="width: 200px"
            v-model="searchForm.operatorGroupId"
            placeholder="请选择团队"
            show-search
            option-filter-prop="children"
            allowClear
            @change="getList(true)"
          >
            <a-select-option v-for="item in allTeamList" :key="item.id" :value="item.id">{{
              item.groupName
            }}</a-select-option>
          </a-select>

          <a-select
            v-model="searchForm.operatorId"
            style="width: 200px"
            placeholder="运营人"
            show-search
            option-filter-prop="children"
            @change="getList(true)"
            allowClear
          >
            <a-select-option v-for="item in allMemberList" :key="item.id" :value="item.id">{{
              item.operatorName
            }}</a-select-option>
          </a-select>

          <a-range-picker
            style="width: 220px"
            :placeholder="['统计时间', '统计时间']"
            :value="checkTimeRange"
            @change="onTimeChange"
            allowClear
          />
        </a-space>
      </div>

      <div style="display: flex; justify-content: space-between; margin: 10px 0">
        <a-input-search
          style="width: 380px; margin-right: 15px"
          placeholder="搜索小红书昵称、小红书号、成员名称、成员编码"
          v-model.trim="searchForm.search"
          @search="getList(true)"
          allowClear
        ></a-input-search>
        <div style="display: flex; align-items: center">
          <a-tooltip placement="bottom">
            <template slot="title">
              <p>
                迟发布：设置了定时发布的笔记推送后，门店进行一键发布/分享发布时，没有早于定时时间一小时（立即发布的笔记没有迟发布）
              </p>
              <p>未发布：定时/立即发布的笔记推送后，门店当日24点前未进行一键发布/分享发布</p>
            </template>
            <span style="cursor: pointer"><a-icon type="question-circle" style="margin-right: 5px" />数据说明</span>
          </a-tooltip>
        </div>
      </div>
    </div>

    <a-table
      :loading="tableLoading"
      :rowKey="(record, index) => index"
      :columns="historyColumns"
      :data-source="dataList"
      :pagination="false"
    >
      <div slot="event" slot-scope="text, record">
        <a-tag color="#4e98ff" v-show="record.eventType == 'NORMAL'">正常发布</a-tag>
        <a-tag color="#fa541c" v-show="record.eventType == 'LATE_PUBLISH'">迟发布</a-tag>
        <a-tag color="#f5317f" v-show="record.eventType == 'UNPUBLISH'">未发布</a-tag>
      </div>
      <div slot="relate" slot-scope="text, record">
        <div>名称：{{ record.memberName || '-' }}</div>
        <div>编号：{{ record.memberCode || '-' }}</div>
        <div>品牌：{{ record.principalName || '-' }}</div>
      </div>

      <div slot="writtingPreview" slot-scope="text, record" class="writting-preview">
        <div class="preview-left" @click="handlePreview(record.objectId)">
          <img
            v-if="record.previewUrl"
            style="height: 100%; width: 100%; object-fit: cover"
            referrerpolicy="no-referrer"
            :src="record.previewUrl"
          />
        </div>

        <div class="preview-right">
          <div class="right-title" @click="handlePreview(record.objectId)">
            <a-popover>
              <template slot="content">
                <p>{{ record.noteTitle ? record.noteTitle : '' }}</p>
              </template>
              {{ record.noteTitle ? record.noteTitle : '-' }}
            </a-popover>
          </div>
          <template v-if="record.topicSelectionTagList && record.topicSelectionTagList.length > 0">
            <div>
              <a-tag
                :color="record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].colorHex : 'blue'"
                style="white-space: break-spaces"
                >{{ record.topicSelectionTagList[0] ? record.topicSelectionTagList[0].tagName : '-' }}</a-tag
              >
              <a-tag v-if="record.topicSelectionTagList.length > 1" color="blue"
                >+{{ record.topicSelectionTagList.length - 1 }}</a-tag
              >
            </div>
          </template>
          <div class="right-xhs" v-show="record.nickname" style="display: flex" @click="handleJumpXHS(record.authorId)">
            <div class="logo">
              <img src="@/assets/icon/xhs_logo.png" alt="" />
            </div>
            <div class="xhs-id blue-text">{{ record.nickname || '-' }}</div>
          </div>
        </div>
      </div>

      <div slot="operation" slot-scope="text, record">
        <a-button type="link">操作</a-button>
      </div>
    </a-table>

    <base-pagination
      :currentPage="pagination.current"
      :options="['15', '30', '50']"
      :pageSize="pagination.pageSize"
      :total="pagination.total"
      @onChange="handlePaginationChange"
      @onShowSizeChange="handlePaginationChange"
    />

    <writtingDrawer
      v-if="showDrawer"
      :readOnly="true"
      @closeDrawer="
        () => {
          showDrawer = false;
          pushId = undefined;
        }
      "
      :pushId="pushId"
    />
  </div>
</template>

<script>
import writtingDrawer from '@/components/xhsAgencyTool/writtingDrawer.vue';
import { historyColumns } from './data';
import moment from 'moment';
import api from './api';

export default {
  name: 'publishHistory',
  props: {},
  components: { writtingDrawer },
  data() {
    return {
      historyColumns,
      currentStep: 1,
      allTeamList: [],
      allMemberList: [],
      allBrandList: [],
      dataList: [],
      tableLoading: false,
      showDrawer: false,
      pushId: undefined,
      pagination: {
        current: 1,
        pageSize: 15,
        total: 1,
      },
      searchForm: {
        principalId: undefined,
        operatorGroupId: undefined,
        operatorId: undefined,
        search: undefined,
      },
      checkTimeRange: [moment().startOf('week'), moment().endOf('week')],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    async init() {
      this.getAllMemberList();
      this.getAllBrandList();
      this.getOperatorTeamList();
    },
    async getList(initPage = false, searchTxt = undefined) {
      if (initPage) this.pagination.current = 1;
      if (searchTxt) this.searchForm.search = searchTxt;
      const startTime = this.checkTimeRange[0]
        ? `${moment(this.checkTimeRange[0]).format('YYYY-MM-DD')} 00:00:00`
        : undefined;
      const endTime = this.checkTimeRange[1]
        ? `${moment(this.checkTimeRange[1]).format('YYYY-MM-DD')} 23:59:59`
        : undefined;
      const param = {
        ...this.searchForm,
        startTime,
        endTime,
        page: this.pagination.current,
        size: this.pagination.pageSize,
        objectType: 'NOTE_PUSH',
      };
      this.tableLoading = true;
      const { code, message, data } = await api
        .getPublishStatisticsEventList(param)
        .finally(() => (this.tableLoading = false));
      if (code === 200) {
        this.dataList =
          data.list?.map((v) => {
            let previewUrl = '';
            if (v.materialArr?.length > 0 && v.materialArr[0].previewUrl) {
              if (
                v.materialArr[0].previewUrl.indexOf('snapshot') == -1 &&
                (v.materialArr[0].previewUrl.indexOf('content') > -1 ||
                  v.materialArr[0].previewUrl.indexOf('afanti') > -1)
              ) {
                if (v.materialArr[0].previewUrl.indexOf('x-oss-process') > -1) {
                  previewUrl = v.materialArr[0].previewUrl += `/resize,l_120,m_lfit`;
                } else {
                  previewUrl = v.materialArr[0].previewUrl += `?x-oss-process=image/resize,l_120,m_lfit`;
                }
              } else {
                previewUrl = v.materialArr[0].previewUrl;
              }
            }
            return { ...v, previewUrl };
          }) || [];
        this.pagination.total = data.total;
      } else {
        this.$message.error(message);
      }
    },
    handleBrandClick(value) {
      this.searchForm.principalId = value;
      this.searchForm.operatorGroupId = undefined;
      this.allTeamList = [];
      this.getOperatorTeamList(value);
      this.getList(true);
    },
    async getOperatorTeamList(principalId = undefined) {
      const { code, message, data } = await api.getTeamManageList({ page: 1, size: 999, principalId });
      if (code === 200) {
        this.allTeamList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async getAllBrandList() {
      return new Promise(async (resolve) => {
        const { code, data, message } = await api.getBrandManageList({ size: 999, page: 1 });
        if (code === 200) {
          this.allBrandList = data.list.filter((v) => v.status === 'NORMAL');
        } else {
          this.$message.error(message);
        }
        resolve();
      });
    },
    async getAllMemberList() {
      const { code, data, message } = await api.getTeamMember({ page: 1, size: 999, isDeleted: 0 });
      if (code === 200) {
        this.allMemberList = data.list;
      } else {
        this.$message.error(message);
      }
    },
    async onTimeChange(dates) {
      this.checkTimeRange = [dates[0], dates[1]];
      this.getList();
    },
    handlePaginationChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.getList();
    },
    handlePreview(objectId) {
      if (objectId) {
        this.pushId = objectId;
        this.showDrawer = true;
      }
    },
    async handleJumpXHS(authorId) {
      if (!authorId) return this.$message.info('账号有误');
      const hide = this.$message.loading('查询中...', 0);
      const { code, data, message } = await api
        .getXhsAccountPage({
          authorId,
        })
        .finally(() => hide());
      if (code === 200) {
        window.open(data, '_blank');
      } else {
        this.$message.error(message);
      }
    },
    handleBack() {
      Object.assign(this.searchForm, this.$options.data().searchForm);
      this.checkTimeRange = [moment().startOf('week'), moment().endOf('week')];
      this.$emit('back');
    },
  },
};
</script>

<style lang="less" scoped>
.search_list {
  margin: 10px 0;
  .search_brand {
    .brand_item {
      color: #adadad;
      padding: 5px 10px;
      border: 1px solid #bfbfbf;
      cursor: pointer;

      &:first-child {
        border-radius: 6px 0 0 6px;
      }

      &:last-child {
        border-radius: 0 6px 6px 0;
      }
    }
  }

  /deep/ .ant-input-group {
    width: unset;
  }
}

.search_brand {
  .all_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    border-radius: 6px;
  }

  .brand_item {
    color: #adadad;
    padding: 5px 10px;
    border: 1px solid #bfbfbf;
    cursor: pointer;
    background-color: #fff;
    line-height: 30px;

    &:first-child {
      border-radius: 6px 0 0 6px;
    }

    &:last-child {
      border-radius: 0 6px 6px 0;
    }

    &.active_item {
      border: 1px solid #1890ff;
      background-color: #1890ff;
      color: #fff;
    }
  }
}

.writting-preview {
  display: flex;

  .preview-left {
    flex-shrink: 0;
    margin-right: 5px;
    width: 60px;
    height: 80px;
    background-size: cover;
    background-position: center;
    cursor: pointer;
    background-color: #cccccc7c;
  }

  .preview-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .right-title {
      max-width: 300px;
      color: #000;
      overflow: hidden;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .right-xhs {
      display: flex;
      justify-content: start;
      align-items: center;

      .logo {
        width: 20px;
        height: 20px;
        overflow: hidden;
        border-radius: 10px;
        margin: 0;
        margin-right: 5px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .xhs-id {
        flex: 1;
        max-width: 145px;
        color: #7f7f7f;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
    .nicknameLine {
      display: flex;
      align-items: center;
    }
  }
}

.blue-text {
  color: #1890ff !important;
  cursor: pointer;
}
</style>
